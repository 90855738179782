console.log('🦄 %cMade by Jaclyn Tan', 'background: #FFF8F5; color: #8D6536; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://jaclyntan.com ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
// import './smoothscroll';
// import './navigation';
import './scrollyclasses';
import './viewport-height';
// import './fw-video';
// import './sliders';
// import './accordions';
// import './fancybox';
// import './gsapanims';

jQuery(function ($) {

	//page
	// var $hamburger = $(".hamburger"),
	// 	$site = $("body"),
	// 	$menu = $(".main-navigation"),
	// 	$menuitems = $(".menu-item"),
	// 	$screenOverlay = $(".screen-overlay");

	// Set the date we're counting down to
	var countDownDate = new Date("November 8, 2023 12:00:00").getTime();

	// Update the count down every 1 second
	var x = setInterval(function () {

		// Get today's date and time
		var now = new Date().getTime();

		// Find the distance between now and the count down date
		var distance = countDownDate - now;

		// Time calculations for days, hours, minutes and seconds
		var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((distance % (1000 * 60)) / 1000);

		// Display the result in the element with id="demo"
		document.getElementById("timer").innerHTML = days + "d " + hours + "h " +
			minutes + "m " + seconds + "s ";

		// If the count down is finished, write some text
		if (distance < 0) {
			clearInterval(x);
			document.getElementById("timer").innerHTML = "Buy the book!";
		}
	}, 1000);

	(function () {
		const html = document.documentElement;
		const layers = document.querySelectorAll('.parallax');
		const speeds = [];

		layers.forEach((layer) => {
			speeds.push(layer.getAttribute('data-speed') || 0);
		});

		let ticking = false;

		function updateParallax() {
			const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

			for (let i = 0; i < layers.length; i++) {
				const translateY = -top * (speeds[i] / 100);
				layers[i].style.transform = `translate3d(0, ${translateY}px, 0)`;
			}

			ticking = false;
		}

		function requestTick() {
			if (!ticking) {
				requestAnimationFrame(updateParallax);
				ticking = true;
			}
		}

		window.addEventListener("scroll", requestTick);

		// Initialize parallax positions on page load
		window.addEventListener("load", updateParallax);
	})();


	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $hamburger.on("click", function () {
	// 	$hamburger.toggleClass("is-active");
	// 	$site.toggleClass("menu-open");
	// 	// if ( $('body').hasClass('menu-open') ) {
	// 	// 	$('#mobile-menu .label').text('Close')
	// 	// } else {
	// 	// 	$('#mobile-menu .label').text('Menu')
	// 	// }
	// });

	// //close menu with an outside click (basically anywhere on .site-content)
	// function closeMenu() {
	// 	$site.removeClass("menu-open");
	// 	$menu.removeClass("toggled");
	// 	$menuitems.removeClass('toggled-on');
	// 	$hamburger.removeClass("is-active");
	// }

	// $screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━ 
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();




});